.print-100 .long-division-container {
    //transform: scale(0.9); /* Default size */
    margin-left: 25px;
  }

@media print {
  @page {
    size: 8.5in 11in; /* Letter size */
    margin: 0.5in; /* Standard margins */
  }

  body {
    margin: 0;
    padding: 0;
    width: 8.5in;
    height: 11in;
  }

  .header {
    height: 1.5in;
    text-align: center;
    font-size: 1.2rem;
  }

  .footer {
    height: 1in;
    text-align: center;
    font-size: 1rem;
    /* margin-top: auto; */
    background-color: red;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  /* Adjust font sizes for printing */
  .problem {
    font-size: 1rem;
  }

  /* Hide unnecessary elements */
  .no-print {
    display: none !important;
  }
}

/* Ensure print scaling is preserved */
/*
@media print {
  .clock-problem {
      max-width: 200px;
      transform: none;
      height: auto;
  }
}
  */

/* Print styles for 20 problems (4x5) */
@media print {
  .print-20 .clock-problem {
    max-width: 400px !important; /* Larger size for 20 problems per page */
    transform: scale(2.5) !important; /* Slightly enlarge */
    margin-bottom: 10px;
  }

  .print-20 .line-container {
    transform: scale(0.8);
  }

  .print-20 .long-division-container {
    max-width: 200px;
    transform: scale(2); /* Default size */
  }

  .print-20 .problems-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }

  .print-20 .problem, .print-20 .clock-problem {
    font-size: 1.5rem;
    padding: 25px;
    align-items: flex-end; /* Ensure alignment for all types */
    transform: scale(1.5); /* Ensure consistent scaling */
  }
  .print-20 .problem .line {
    width: 100%;
  }
}

/* Print styles for 50 problems (5x10) */
@media print {
  .print-50 .clock-problem {
    max-width: 200px;
    transform: scale(1); /* Default size */
  }

  .print-50 .long-division-container {
    max-width: 200px;
    transform: scale(1.5); /* Default size */
  }


  .print-50 .problem, .print-50 .clock-problem {
    font-size: 1.25rem;
    transform: scale(1);
    align-items: flex-end; /* Ensure alignment for all types */
  }
  .print-50 .problem .line {
    width: 100%;
  }

  .print-50 .problems-grid {
    grid-auto-rows: 85px;
  }
}

/* Print styles for 100 problems (10x10) */
@media print {
  .print-100 .clock-problem {
    max-width: 200px;
    transform: scale(1); /* Default size */
  }

  .print-100 .long-division-container {
    max-width: 200px;
    transform: scale(1); /* Default size */
  }

  .print-100 .problem, .print-100 .clock-problem {
    font-size: 1.25rem;
    transform: scale(.9);
    align-items: flex-end; /* Ensure alignment for all types */
  }
  .print-100 .problem .line {
    width: 100%;
  }

  .print-100 .problems-grid {
    grid-auto-rows: 85px;
  }
}
