/* TimedModeTimer.css */

/* Base styles for individual digits */
.flip-digit {
    display: inline-block;
    width: 1.5em;
    height: 2em;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 2em;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

.flip-digit:hover {
    transform: rotateX(180deg);
}

/* Colon between minutes and seconds */
.colon {
    display: inline-block;
    width: 0.5em;
    color: #fff;
}

/* Flip clock container styling */
.flip-clock {
    display: flex;
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Courier New', Courier, monospace;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 8px;
    align-items: center;
}

/* Desktop adjustments for larger screens */
@media (min-width: 769px) {
    .flip-clock {
        position: fixed;
        top: 25%;          /* Centered vertically */
        right: 20%;       /* Positioned to the right */
        transform: translateY(-50%);
        z-index: 1000;     /* Ensures it floats above other content */
    }
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .flip-clock {
        font-size: 1.2rem;         /* Smaller font size on mobile */
        padding: 6px 8px;          /* Reduce padding */
        position: relative;           /* Fix position to avoid overlap */
        opacity: 0.9;              /* Slightly transparent */
        z-index: 1000;             /* Keep it on top of other elements */
        transform: scale(0.85);    /* Scale down overall size */
        margin-top: 20px; /* Add spacing below the buttons */
    }
}