@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

header {
  text-align: center;
  margin-bottom: 20px;
}

.problems-grid {
  display: grid;
  grid-auto-rows: 145px;
  justify-items: center;
}

/* Problem styles */
.problem .line-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.problem .line {
  display: block;
  border-top: 1px solid black;
  text-decoration: none;
  margin-top: 5px;
  width: 100%;
}

.problem {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Fira Code', monospace;
  transform: scale(0.9); /* Control scaling here */
}

/* Make sure scaling is consistent for all elements */
.problem span {
  font-size: inherit; /* Ensure all numbers take parent font size */
  flex-shrink: 0; /* Prevent unexpected shrinking */
}

.name-line {
  display: block;
  border-bottom: 1px solid black;
  width: 100%;
}

.problem .num1, 
.problem .operator-num2 {
  text-align: right; /* Maintain right-alignment */
  width: 100%; /* Ensure full width for alignment */
}

.clock-line {
  padding: 0; /* Remove padding */
  margin-top: 5px; /* Adjust margin to match .line for consistency */
  width: 100%;
  display: block;
  border-bottom: 1px solid black;
}

.clock-problem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 300px; /* Desktop default size */
  margin: 0 auto;
}

/* Mobile-specific adjustment */
@media screen and (max-width: 768px) {
  .clock-problem {
    max-width: 150px; /* Smaller size on mobile */
    transform: scale(0.8); /* Optional scaling for better fit */
}

  .problems-grid {
    grid-auto-rows: 145px; /* Ensure consistent height across problems */
  }
}

/* Ensure print scaling is preserved */
@media print {
  .clock-problem {
      max-width: 200px; /* Adjust if needed */
      transform: none;  /* Disable transform for clean print */
      height: auto;
  }
}

/* Print styles for 20 problems (4x5) */
@media print {
  .print-20 .problems-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }
  .print-20 .problem, .print-20 .clock-problem {
    font-size: 1.5rem;
    padding: 25px;
    align-items: flex-end; /* Ensure alignment for all types */
    transform: scale(0.9); /* Ensure consistent scaling */
/* test */
  }
  .print-20 .problem .line {
    width: 100%;
  }
}

/* Print styles for 50 problems (5x10) */
@media print {
  .print-50 .problem, .print-50 .clock-problem {
    font-size: 1.25rem;
    transform: scale(0.7);
    align-items: flex-end; /* Ensure alignment for all types */
  }
  .print-50 .problem .line {
    width: 100%;
  }

  .print-50 .problems-grid {
    grid-auto-rows: 60px;
  }
}

/* Print styles for 100 problems (10x10) */
@media print {
  .print-100 .problem, .print-100 .clock-problem {
    font-size: 1.25rem;
    transform: scale(.7);
    align-items: flex-end; /* Ensure alignment for all types */
  }
  .print-100 .problem .line {
    width: 100%;
  }

  .print-100 .problems-grid {
    grid-auto-rows: 60px;
  }
}

/* Ensure mobile display shows 5x20 problems (Only for screen, not print) */
@media screen and (max-width: 768px) {
  .print-100 .problems-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 columns for display */
    grid-auto-rows: auto; /* Auto-adjust row height */
  }

  .print-100 .problem, .print-100 .clock-problem {
    font-size: 1rem; /* Adjust the size for better readability */
    transform: scale(0.8); /* Scale down slightly */
  }

  .print-20 .problem, .print-20 .clock-problem,
  .print-50 .problem, .print-50 .clock-problem {
    height: auto; /* Ensure proper height on mobile for 20 and 50 */
    transform: scale(0.9); /* Ensure proper scale */
  }
  /* test */
  .clock-problem {
    height: 100%; /* Ensure full height for mobile display */
    align-items: flex-start; /* Ensure alignment for mobile */
  }

  .print-20 .problem, .print-20 .clock-problem,
  .print-50 .problem, .print-50 .clock-problem,
  .print-100 .problem, .print-100 .clock-problem {
    font-size: 1rem; /* Ensure readability */
    transform: scale(0.9); /* Slightly adjust scaling */
  }

  .problems-grid {
    grid-auto-rows: 145px; /* Consistent height */
  }
  /* test */
}

/* Hide non-essential elements when printing */
@media print {
  header, .print-footer {
    width: 100%;
    left: 0;
  }

  .footer-div {
    margin: 15px 0px 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  header {
    top: 0;
    background: white;
    padding: 10px 0;
    text-align: center;
  }

  .print-footer {
    bottom: 0;
    background-color: transparent;
    text-align: center;
    padding: 5px 0 0 0;
  }

  .print-footer p {
    margin: 0;
  }

  .no-print {
    display: none;
  }

  .generate-buttons {
    display: none; /* Hide these elements when printing */
  }

  .print-header {
    visibility: visible;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f5f5f5; /* Light, warm background color */
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
.App {
  font-family: sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');