/* General Styling */
.long-division-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    font-family: "Fira Mono", monospace;
    width: auto;
    text-align: center;
}

.long-division-svg {
    display: block;
    width: 150px; /* Adjust based on container size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto;
}

/* Divisor and Dividend Styling */
.long-division-svg text {
    font-size: 1rem;
    font-family: "Fira Mono", monospace;
    fill: black; /* Ensure text is visible */
    dominant-baseline: middle; /* Align text vertically */
    text-anchor: middle; /* Align text horizontally */
}

/* Vinculum (Bracket Line) */
.long-division-svg line {
    stroke: black;
    stroke-width: 2;
}

/* Print-Specific Styling */
@media print {
    .long-division-container {
        margin: 5px 0;
    }

    .long-division-svg {
        width: 120px; /* Adjust size for print */
    }

    .long-division-svg text {
        font-size: 0.9rem;
        font-family: "Fira Mono", monospace;
        font-weight: normal;
        fill: black;
    }

    .long-division-svg line {
        stroke-width: 1.5; /* Adjust thickness for print clarity */
    }
}