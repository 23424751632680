/* BeadFrame.css */
.bead-frame-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  user-select: none; /* Disables text selection */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

.bead-frame {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  height: 10vh; /* Adjust height relative to viewport height */
  margin: 10px 0;
  width: 90%; /* Bead frame takes up 90% of container width */
}

.bead-frame-line {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: gray;
  transform: translateY(-50%);
}

.bead-frame-label {
  position: absolute;
  top: 0;
  left: -50px;
  font-weight: bold;
  font-size: 1.2em;
}

.bead {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6),
    0 4px 8px rgba(0, 0, 0, 0.4);
  transition: none;
}

.bead::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  width: 60%;
  height: 60%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  border-radius: 50%;
  pointer-events: none;
}

.bead-frame-tally {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
}

.tally-total {
  font-weight: bold;
  margin-top: 10px;
}

.problem-display {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #374151;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.bead-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.chevron-left-container,
.chevron-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%; /* Adjust width as needed */
  z-index: 100;
}

.bead-row-icon {
  font-size: 4vw; /* Responsive icon size */
  cursor: pointer;
  text-shadow: 2px 2px #20202088;
  background-color: transparent;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border: none;
}

.bead-row-icon.active {
  color: #15803d;
}

.bead-row-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .bead-frame-label {
    font-size: 1em;
    left: -40px;
  }

  .bead-frame {
    height: 8vh;
  }

  .bead-frame-container {
    padding: 0 10px;
  }

  .problem-display {
    font-size: 1.8rem;
  }
}

 /* Prevent default touch actions like scrolling within the bead frame container */
.bead-frame-container {
  touch-action: none;
}

 /* Allow touch gestures for the chevrons */
.chevron {
  touch-action: auto;
  cursor: pointer;
}