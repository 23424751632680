/* General Footer Styles */
.footer {
    width: 100%;
    padding: 2rem 1rem;
    background-color: #1a202c; /* Dark gray */
    color: #e2e8f0; /* Light gray */
    font-size: 0.875rem; /* Text-sm equivalent */
}

.footer.kidified {
    background-color: #d946ef; /* Kidified purple background */
    color: white;
}

.footer.adultified {
    background-color: #1a202c; /* Adultified dark background */
    color: #e2e8f0; /* Light gray text */
}

.footer-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    text-align: center;
}

@media (min-width: 768px) {
    .footer-grid {
        grid-template-columns: 1fr 2fr 1fr; /* Three columns layout on larger screens */
        text-align: left;
    }
}

/* Footer Sections */
.footer-left,
.footer-right {
    text-align: center;
}

.footer-right a:hover {
    text-decoration: underline;
    text-decoration-color: #d946ef; /* or decoration-fuchsia-500 */
}

@media (min-width: 768px) {
    .footer-left {
        text-align: left;
    }

    .footer-right {
        text-align: right;
    }
}

.footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

@media (min-width: 768px) {
    .footer-links {
        align-items: flex-start;
    }
}

.footer-links a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: inherit;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
    text-decoration-color: #d946ef;
}

.footer-links a svg {
    flex-shrink: 0;
}

/* Print-Specific Styles */
@media print {
    .footer {
        background-color: transparent;
        color: black;
        display: block !important;
    }

    .footer-grid {
        grid-template-columns: 1fr 1fr; /* Simplify for print */
    }

    .footer-center {
        display: none; /* Hide social links on print */
    }
}